import React, { PropsWithChildren, useState } from "react";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MyDrawer from "./drawer";
import { Logo } from "src/assets";
// import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import MyMenu from "./myMenu";
import { Link } from "react-router-dom";
import Paths from "../utils/Paths";

const headersData = [
  {
    label: "About Us",
    href: Paths.AboutUs,
    sub: [
      { label: "Our History", href: Paths.OurHistory },
      // { label: "Awards & Accolades", href: Paths.Awards },
      { label: "Corporate Social Responsibilities", href: Paths.CSR },
      { label: "Our Team", href: Paths.OurTeam },
    ],
  },
  {
    label: "Products & Services",
    href: Paths.Products,
    sub: [
      { label: "Products", href: Paths.Products },
      { label: "Services", href: Paths.ProductServices },
    ],
  },
  {
    label: "News & Media",
    href: Paths.NewsListing,
    sub: [],
  },
  {
    label: "Sustainability Reports",
    href: Paths.SustainabilityReports,
    sub: [],
  },
  {
    label: "Careers",
    href: Paths.Career,
    sub: [],
  },
  {
    label: "Resource Links",
    href: Paths.ResourceLinks,
    sub: [],
  },
  {
    label: "Contact Us",
    href: Paths.Contact,
    sub: [],
  },
];

export interface TopNavProps extends PropsWithChildren {}
const TopNavigation: React.FC<TopNavProps> = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const getMenuButtons = () => {
    return headersData.map(({ label, href, sub }, index) => {
      return <MyMenu menuItems={sub} label={label} href={href} key={index} />;
    });
  };

  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: "white" }}>
        <Toolbar
          sx={{
            display: { xs: "flex", sm: "inherit" },
            justifyContent: { xs: "inherit", sm: "space-between" },
          }}
        >
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="h1">
            <Link to="/">
              <Logo sx={{ width: "230px", height: "30px" }} />
            </Link>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {getMenuButtons()}
          </Box>
        </Toolbar>
      </AppBar>
      <MyDrawer
        navItems={headersData}
        drawerOpenFlag={mobileOpen}
        drawerToggle={handleDrawerToggle}
      />
    </Box>
  );
};

export default TopNavigation;
