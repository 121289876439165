import React, { PropsWithChildren } from "react";
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { globalStyles } from "../utils/style";
import {
  Collapse,
  Container,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_sustainbilityReport } from "src/assets";
import { ArrowDropDown, ArrowRight, PictureAsPdf } from "@mui/icons-material";

export interface SustainabilityReportPageProps extends PropsWithChildren {}
const SustainabilityReportPage: React.FC<SustainabilityReportPageProps> = (
  props
) => {
  const [isChecked, setIsChecked] = React.useState(true);

  return (
    <>
      <TopNavigation />

      <TopHeaderBanner image={Banner_sustainbilityReport}>
        <Typography variant="h1" sx={globalStyles.bannerHeaderText}>
          Sustainability
        </Typography>
        <Typography variant="h1" sx={globalStyles.bannerHeaderText}>
          Report
        </Typography>
      </TopHeaderBanner>

      <Container sx={{ py: 4 }}>
        <Typography
          variant="h5"
          pb={2}
          onClick={() => {
            setIsChecked((prev) => !prev);
          }}
          sx={{ cursor: "pointer" }}
        >
          {isChecked ? <ArrowDropDown /> : <ArrowRight />}
          Sustainability Reports
        </Typography>
        <Collapse in={isChecked}>
          <ul>
            <li style={{ alignItems: "center" }}>
              <span>Sustainability Reports 2023</span>
              <IconButton
                onClick={() => {
                  window.open(
                    "https://www.terajupharma.com/sustainability-report/17%20Dec%20updated%20Sustainability%20Report%202023.pdf"
                  );
                }}
              >
                <PictureAsPdf />
              </IconButton>
            </li>
          </ul>
        </Collapse>

        <Divider sx={{ my: 3 }} />
      </Container>

      <MyFooter />
    </>
  );
};

export default SustainabilityReportPage;
